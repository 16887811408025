<template>
  <v-card>
    <v-card-text>
      <v-row dense>
        <v-col
          cols="12"
          sm="4"
        >
          <v-card
            color="grey lighten-3"
            class="fill-height d-flex flex-column justify-space-between"
            dense
          >
            <div>
              <v-card-title>
                <h2 v-t="'support.knowledgeBase'" />
              </v-card-title>
              <v-card-text class="text-left">
                <p v-t="'support.useCasesWithDetailedFeatureDescriptions'" />
              </v-card-text>
            </div>
            <v-card-actions class="flex-wrap">
              <v-btn
                v-for="(href, key) in documentation.knowledgeBase"
                :key="key"
                :href="href"
                text
                target="_blank"
                rel="noreferrer"
                class="ml-0 mr-2"
                :data-cy="`support-knowledge-base-${key}-button`"
              >
                {{ $t(`support.documentation.${key}`) }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          sm="4"
        >
          <v-card
            color="grey lighten-3"
            class="fill-height d-flex flex-column justify-space-between"
            dense
          >
            <div>
              <v-card-title>
                <h2 v-t="'support.faq'" />
              </v-card-title>
              <v-card-text class="text-left mb-6 mb-sm-0">
                <p v-t="'support.commonTroublesAndSolutions'" />
              </v-card-text>
            </div>
            <v-card-actions class="flex-wrap">
              <v-btn
                v-for="(href, key) in documentation.faq"
                :key="key"
                :href="href"
                text
                target="_blank"
                rel="noreferrer"
                class="ml-0 mr-2"
                :data-cy="`support-faq-${key}-button`"
              >
                {{ $t(`support.documentation.${key}`) }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          sm="4"
        >
          <v-card
            color="grey lighten-3"
            class="fill-height d-flex flex-column justify-space-between"
            dense
          >
            <div>
              <v-card-title>
                <h2 v-t="'support.ownersManual'" />
              </v-card-title>
              <v-card-text class="text-left mb-6 mb-sm-0">
                <p v-t="'support.configurationHandbook'" />
              </v-card-text>
            </div>
            <v-card-actions class="flex-wrap">
              <v-btn
                v-for="(href, key) in documentation.manual"
                :key="key"
                :href="href"
                text
                target="_blank"
                rel="noreferrer"
                class="ml-0 mr-2"
                :data-cy="`support-manual-${key}-button`"
              >
                {{ $t(`support.documentation.${key}`) }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider />
    <v-card-text class="text-center pb-1">
      <span
        v-t="
          'support.inCaseYouStillDidntFindYourSolutionYouCanReachOurSupportTeamRightHere'
        "
      />
      <v-row class="mt-3">
        <v-col>
          <material-support-new-ticket-modal-window />
        </v-col>
        <v-col>
          <material-request-callback-modal-window />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { DOCUMENTATION } from '@/constants/support'

export default {
  data: () => {
    return {
      documentation: DOCUMENTATION
    }
  }
}
</script>
